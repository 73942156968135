<script>
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'

export default {
  name: 'desktop-toolbar-v3',
  components: {
    BaseIcon,
    BaseAction,
  },
  setup() {
    const { isLeftMenuAppearanceSidebar } = useCms()
    const { leftMenu, settingsMenu, uiReady } = useUI()

    return {
      isLeftMenuAppearanceSidebar,
      leftMenu,
      settingsMenu,
      uiReady,
      ui,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    activeId() {
      return this.activeTopMenuItem?.id ?? null
    },
    isHome() {
      return this.$route.name === 'home'
    },
    isBorder() {
      return !this.isHome && ui.desktop && this.isLeftMenuAppearanceSidebar
    },
  },
  methods: {
    openMenu() {
      modal.show('side-menu-modal')
    },
  },
}
</script>

<template>
  <div
    class="flex flex-col content-between"
    :class="{
      'border-r': isBorder,
    }"
  >
    <nav
      role="navigation"
      aria-label="Main"
      class="flex grow flex-col space-y-4 pr-4 pt-5"
    >
      <template v-for="{ id, action, icon, label } in leftMenu">
        <v-popover
          v-if="uiReady && action && action.enabled"
          :key="id"
          offset="16"
          placement="right"
          trigger="hover"
          popover-base-class="toolbar-item-popover z-1"
          popover-wrapper-class=""
          popover-inner-class=""
        >
          <base-action
            v-bind="action"
            :ref="activeId === id ? 'activeItemElement' : null"
            :label="label"
            color=""
            :current="activeId === id"
            class="flex h-14 w-20 items-center justify-center rounded-r-lg pl-4 transition-all duration-75"
            :class="{
              'bg-primary text-white': activeId === id,
              'text-default hover:bg-eonx-neutral-50 hover:text-eonx-neutral-600':
                activeId !== id,
            }"
          >
            <base-icon :svg="icon" :size="32" :alt="label" />
          </base-action>
          <template #popover>
            <div class="toolbar-item-popover px-4 py-2.5 text-white">
              <div
                class="toolbar-item-popover__bg absolute left-0 top-0 h-full rounded-md bg-primary"
              ></div>
              <div
                class="toolbar-item-popover__text relative min-w-0 bg-transparent p-0 text-base font-semibold shadow-none transition"
              >
                {{ label }}
              </div>
            </div>
          </template>
        </v-popover>
        <div
          v-else
          :key="id"
          class="flex h-14 w-20 items-center justify-center pl-4 text-eonx-neutral-600"
        >
          <base-icon :svg="icon" :size="32" :alt="label" />
        </div>
      </template>
    </nav>
    <base-action
      v-if="settingsMenu.length > 0"
      type="none"
      color=""
      label="settings menu"
      look="filled"
      aria-haspopup="menu"
      :class="[
        'mb-2.5 flex h-10 w-10 items-center justify-center rounded-lg text-default transition-all hover:bg-subtlest',
        leftMenu.length > 0 ? 'mx-auto' : 'mx-6',
      ]"
      @click="openMenu"
    >
      <base-icon svg="v2/box-icons/bx-cog" :size="32" />
    </base-action>
  </div>
</template>
