<script>
import { getMenuActiveItem } from '/~/utils/menu'
import { useCms } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'
import LayoutView from '/~/layouts/view/layout-view.vue'

export default {
  name: 'desktop-side-menu',
  components: {
    LayoutView,
    LayoutRounded,
  },
  setup() {
    const { isLeftMenuAppearanceSidebar } = useCms()
    const { leftMenu, uiReady } = useUI()

    return {
      isLeftMenuAppearanceSidebar,
      leftMenu,
      uiReady,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    menu() {
      return this.activeTopMenuItem?.children ?? []
    },
    label() {
      return this.activeTopMenuItem?.label
    },
    description() {
      return this.activeTopMenuItem?.description
    },
    hideMenu() {
      return this.$route.meta.hideMenu
    },
    isHome() {
      return this.$route.name === 'home'
    },
    isRecroom() {
      return this.$route.path.includes('rec-room')
    },
    isShowSideMenu() {
      return (
        (this.menu.length ||
          this.description?.replace(/<\/?p>/gim, '').trim()) &&
        !this.isRecroom &&
        this.isLeftMenuAppearanceSidebar
      )
    },
  },
}
</script>

<template>
  <layout-view
    v-if="isShowSideMenu"
    :heading="label"
    :description="description"
    :menu="menu"
  >
    <slot name="description" />
    <slot />
  </layout-view>

  <layout-rounded v-else-if="isHome">
    <slot />
  </layout-rounded>

  <div
    v-else
    id="sidebar-content"
    class="relative h-full w-full overflow-y-auto overflow-x-hidden"
  >
    <slot />
  </div>
</template>
